import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableViewComponent } from './components/table-view/table-view.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { KiloMegaPipe } from './pipes/kilomega.pipe';
import { DecamelPipe } from './pipes/decamel.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule } from 'primeng/tree';
import { ListboxModule } from 'primeng/listbox';
import { PermissionCheckPipe } from './pipes/permission-check.pipe';
import { MapComponent } from './components/map/map.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NotificationEventsComponent } from './components/notification-events/notification-events.component';
import { TreeTableModule } from 'primeng/treetable';
import { ChipsModule } from 'primeng/chips';
import { LoaderComponent } from './components/loader/loader.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { TreeComponent } from './components/tree/tree.component';
import { AccordionModule } from 'primeng/accordion';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiLabelPipe } from './pipes/multilabel.pipe';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DeviceErrorComponent } from './components/device-error/device-error.component';
import { ModalComponent } from './components/modal/modal.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

const sharedModules = [
    CommonModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    CheckboxModule,
    SkeletonModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    DialogModule,
    ButtonModule,
    ToastModule,
    MessagesModule,
    DropdownModule,
    TooltipModule,
    MenubarModule,
    MultiSelectModule,
    TranslateModule,
    TreeModule,
    ListboxModule,
    AutoCompleteModule,
    TreeTableModule,
    ChipsModule,
    AccordionModule,
    InputSwitchModule,
    RadioButtonModule,
    DynamicDialogModule,
    NgJsonEditorModule
]

const sharedComponents = [
    NavbarComponent,
    SidebarComponent,
    TableViewComponent,
    TreeComponent,
    LoaderComponent,
    NoDataFoundComponent,
    NotificationEventsComponent,
    DateAgoPipe,
    KiloMegaPipe,
    MultiLabelPipe,
    PermissionCheckPipe,
    MapComponent,
    DecamelPipe,
    DeviceErrorComponent,
    ModalComponent
]
@NgModule({
    declarations: [
        ...sharedComponents
    ],
    imports: [...sharedModules],
    exports: [...sharedModules, ...sharedComponents]
})
export class SharedModule { }
