import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalDialogOptions } from './modal-dialog-options';

@Injectable({
    providedIn: 'root',
})
export class DynamicModalService {
    private modals: { [id: string]: BehaviorSubject<ModalDialogOptions | null> } = {};

    getModalConfig$(id: string) {
        if (!this.modals[id]) {
            this.modals[id] = new BehaviorSubject<ModalDialogOptions | null>(null);
        }
        return this.modals[id].asObservable();
    }

    openModal(config: ModalDialogOptions) {
        const id = config.id || 'default';
        if (!this.modals[id]) {
            this.modals[id] = new BehaviorSubject<ModalDialogOptions | null>(null);
        }
        this.modals[id].next(config);
    }

    closeModal(id: string = 'default') {
        if (this.modals[id]) {
            this.modals[id].next(null);
        }
    }
}
