import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalDialogOptions } from './modal-dialog-options';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicModalService } from './dynamic-modal.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
    providers: [DialogService]
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string = 'default';
    modalConfig: ModalDialogOptions | null = null;
    isVisible: boolean = false;
    private subscription: Subscription | null = null;

    constructor(private modalService: DynamicModalService) { }

    ngOnInit() {
        this.subscription = this.modalService.getModalConfig$(this.id).subscribe((config) => {
            this.modalConfig = config;
            this.isVisible = !!config;
        });
    }

    onCancel() {
        if (this.modalConfig?.onCancel) {
            this.modalConfig.onCancel();
        } else {
            this.modalService.closeModal(this.id);
        }
    }

    async onSubmit() {

        if (this.modalConfig) {
            this.modalConfig.isLoading = true;
            this.modalConfig.errorMessage = '';
        }

        try {

            if (this.modalConfig?.onSubmit) {
                await this.modalConfig.onSubmit();
            }

            this.modalService.closeModal(this.id);

        } catch (error: any) {

            if (this.modalConfig) {
                this.modalConfig.errorMessage = error.message || 'An unexpected error occurred.';
            }

        } finally {

            if (this.modalConfig) {
                this.modalConfig.isLoading = false;
            }
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}