import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multilabel',
    pure: true
})
export class MultiLabelPipe implements PipeTransform {

    transform(values: string[]): string {
        if (!values || !Array.isArray(values)) return '';

        const displayedNames = values.slice(0, 1).join(', ');
        const remainingCount = values.length - 1;

        return remainingCount > 0
            ? `${displayedNames}, ... +${remainingCount} more`
            : displayedNames;
    }

}