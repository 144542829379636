import { Component, Input } from '@angular/core';
import { TableColumn } from '../table-view/tablecolumn';
import { DeviceErrorMessageData } from 'src/app/modules/device-management/entities/api/deviceerrormessagedata';

@Component({
    selector: 'device-error',
    templateUrl: './device-error.component.html',
    styleUrl: './device-error.component.scss'
})
export class DeviceErrorComponent {
    @Input() errorMessageData: DeviceErrorMessageData[] = [];
    errorTableHeader: TableColumn[] = [
        { field: 'name', header: 'Name', type: 'string', sort: true },
        {
            field: 'icon', header: 'Status', type: 'icon', sort: true, tooltipFactory: (row: any) => {
                const successIcon = '<i class="pi pi-check-circle"></i>';
                const errorIcon = '<i class="pi pi-times" ></i>';
                const processingIcon = '<i class="pi pi-spin pi-spinner"></i>';
                const pendingIcon = '<i class="pi pi-question"></i>';

                const name = row?.icon == successIcon ? 'Success' : row?.icon == errorIcon ? 'Failed' : row?.icon == processingIcon ? 'Processing' : 'Pending';
                return `${name}`;
            }
        }
    ];
}
